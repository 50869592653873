// import * as Push from 'push.js';

export const isOnline = () => {
  if (typeof window.navigator !== 'object' || typeof window.navigator.onLine !== 'boolean') {
    return true;
  }

  return window.navigator.onLine;
};

export const online = (callback, add = true) => {
  if (typeof callback === 'function') {
    if (add) {
      window.addEventListener('online', callback, { capture: true });
    } else {
      window.removeEventListener('online', callback, { capture: true });
    }
  }
};

export const offline = (callback, add = true) => {
  if (typeof callback === 'function') {
    if (add) {
      window.addEventListener('offline', callback, { capture: true });
    } else {
      window.removeEventListener('offline', callback, { capture: true });
    }
  }
};

let geolocationWatcherId;
let previousLatitude = 0;
let previousLongitude = 0;
export const geolocation = (success, fail) => {
  if (window.navigator !== null && typeof window.navigator === 'object' && window.navigator.geolocation !== null && typeof window.navigator.geolocation === 'object') {
    if (typeof success !== 'function' || geolocationWatcherId !== undefined) {
      window.navigator.geolocation.clearWatch(geolocationWatcherId);
      geolocationWatcherId = undefined;
    } else {
      geolocationWatcherId = window.navigator.geolocation.watchPosition((position) => {
        if (typeof success === 'function' && previousLatitude !== position.coords.latitude && previousLongitude !== position.coords.longitude) {
          success(position.coords.latitude, position.coords.longitude);
          previousLatitude = position.coords.latitude;
          previousLongitude = position.coords.longitude;
        }
      }, () => {
        if (typeof fail === 'function' && isOnline()) {
          fail();
        }
      }, {
        enableHighAccuracy: true,
        maximumAge: 60000,
        timeout: 10000
      });
    }
  }
};

export const getGeolocation = async () => {
  if (window.navigator !== null && typeof window.navigator === 'object' && window.navigator.geolocation !== null && typeof window.navigator.geolocation === 'object'
    && window.navigator.permissions !== null && typeof window.navigator.permissions === 'object' && typeof window.navigator.permissions.query === 'function') {
    const permission = await window.navigator.permissions.query({ name: 'geolocation' });

    if (typeof permission === 'object') {
      if (['prompt', 'granted'].includes(permission.state)) {
        try {
          return await (new Promise((resolve, reject) => window.navigator.geolocation.getCurrentPosition(
            (pos) => resolve(pos.coords),
            (error) => reject(error),
            {
              enableHighAccuracy: false,
              maximumAge: 60000,
              timeout: 1000
            }
          )));
        } catch (geolocationPositionError) {
          throw new Error(geolocationPositionError.message);
        }
      }
    }
  }

  return undefined;
};

export const geolocationIsAllowed = async () => {
  if (window.navigator !== null && typeof window.navigator === 'object' && window.navigator.geolocation !== null && typeof window.navigator.geolocation === 'object'
    && window.navigator.permissions !== null && typeof window.navigator.permissions === 'object' && typeof window.navigator.permissions.query === 'function') {
    const permission = await window.navigator.permissions.query({ name: 'geolocation' });

    if (typeof permission === 'object') {
      if (permission.state === 'prompt') {
        return await (new Promise((resolve) => window.navigator.geolocation.getCurrentPosition(() => resolve(true), () => resolve(false), {
          enableHighAccuracy: false,
          maximumAge: 60000,
          timeout: 1000
        })));
      }

      return permission.state === 'granted';
    }
  }

  return true;
};

export const notification = (title, body, success, fail) => {
  success();
  // const options = {
  //   badge: 'https://burgerbreton.net/cdn/image/favicon/128x128/circle/blue/burger-breton.png',
  //   body: body,
  //   icon: 'https://burgerbreton.net/cdn/image/logo/unsigned/square/500w/blue/burger-breton.png',
  //   lang: 'FR',
  //   requireInteraction: true,
  //   silent: false,
  //   vibrate: true
  // };
  //
  // try {
  //   Push.create(title, options).then(success).catch(fail);
  // } catch (error) {
  //   if (typeof error.message === 'string' && error.message.includes('permission')) {success();} else {fail(error);}
  // }
};

export const isHidden = () => {
  if (typeof document.hidden === 'boolean') { // Opera 12.10 and Firefox 18 and later support
    return document.hidden;
  } else if (typeof document.msHidden === 'boolean') {
    return document.msHidden;
  } else if (typeof document.webkitHidden === 'boolean') {
    return document.webkitHidden;
  }

  return false;
};

export const visibility = (callback, add = true) => {
  let eventType;
  if (typeof document.hidden !== 'undefined') { // Opera 12.10 and Firefox 18 and later support
    eventType = 'visibilitychange';
  } else if (typeof document.msHidden !== 'undefined') {
    eventType = 'msvisibilitychange';
  } else if (typeof document.webkitHidden !== 'undefined') {
    eventType = 'webkitvisibilitychange';
  }

  if (eventType !== undefined && typeof callback === 'function') {
    if (add) {
      window.addEventListener(eventType, callback, { capture: true });
    } else {
      window.removeEventListener(eventType, callback, { capture: true });
    }
  }
};
