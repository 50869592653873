import Vue from 'vue';
import App from '@/App.vue';
import router from '@/router';
import store from '@/store';
import '@/plugins/base';
import '@/plugins/vee-validate';
import vuetify from '@/plugins/vuetify';
import i18n from '@/i18n';
import http from '@/http';
import VueMobileDetection from 'vue-mobile-detection';
import { abilitiesPlugin } from '@casl/vue';
import ability from '@/ability';
import { getPageName, handleRequestError } from '@/services/common/Http';
import * as Sentry from '@sentry/vue';
import { Integrations } from '@sentry/tracing';

Vue.config.productionTip = false;

Vue.prototype.$http = http;

store.watch((state) => {
  if (state.token !== undefined) {
    Vue.prototype.$http.defaults.headers.common.Authorization = 'Bearer ' + String(state.token);
  }
}, undefined);

if (process.env.VUE_APP_MODE === 'production') {
  // if (location.href?.startsWith('https://app.order-picker.onylab.com')) { // TODO tmp
  //   location.href = process.env.VUE_APP_BASE_APP_URL;
  // }

  Sentry.init({
    Vue,
    dsn: 'https://4a5fda0bad1b444699fe476d497128b2@o996279.ingest.sentry.io/5955017',
    integrations: [
      new Integrations.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: ['localhost', 'localhost:3000', 'localhost:8080', '192.168.86.164:8080', 'app.order-picker.onylab.com', 'api.order-picker.onylab.com',
          'op.burgerbreton.net', 'api.op.burgerbreton.net', 'op-bb.cleverapps.io', 'api-op-bb.cleverapps.io']
      })
    ],
    attachProps: true,
    logErrors: true,
    environment: process.env.VUE_APP_MODE,
    maxBreadcrumbs: 50,
    // Setting a uniform sample rate for all transactions using the tracesSampleRate option in your SDK config to a number between 0 and 1.
    // (For example, to send 20% of transactions, set tracesSampleRate to 0.2.)
    tracesSampleRate: 0.05,
    trackComponents: true
  });
}

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App)
}).$mount('#app');

Vue.use(VueMobileDetection);

if (getPageName() !== 'login' && (store.state.token === undefined || store.state.token === null)) {
  const error = new Error('logout');
  error.response = { status: 401 };
  handleRequestError(error, router);
}

Vue.use(abilitiesPlugin, ability(store.state.user), {
  useGlobalProperties: true
});
