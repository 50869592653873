import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersist from 'vuex-persist';

Vue.use(Vuex);

const vuexPersist = new VuexPersist({
  key: 'orderpicker',
  storage: window.localStorage
});

const initPage = (state, payload) => {
  const pageName = (payload.pageName !== undefined ? String(payload.pageName) : String(undefined));
  if (typeof state.page[pageName] !== 'object') {
    state.page[pageName] = {};
  }

  if (typeof state.page[pageName].info !== 'object') {
    state.page[pageName].info = {};
  }

  return pageName;
};

export default new Vuex.Store({
  state: {
    barColor: 'rgba(0, 0, 0, .8), rgba(0, 0, 0, .8)',
    barImage: '',
    drawer: false,
    token: undefined,
    user: undefined,
    location: undefined,
    locations: [],
    page: {}
  },
  mutations: {
    SET_BAR_IMAGE (state, payload) {
      state.barImage = payload;
    },
    SET_DRAWER (state, payload) {
      state.drawer = payload;
    },
    SET_TOKEN (state, payload) {
      state.token = payload;
      if (state.token !== undefined) {
        Vue.prototype.$http.defaults.headers.common.Authorization = 'Bearer ' + String(state.token);
      }
    },
    SET_LOCATION (state, payload) {
      if (payload === undefined) {
        state.location = undefined;
        state.locations = undefined;
      } else {
        state.location = payload.location;
        state.locations = payload.locations;
      }
    },
    SET_USER (state, payload) {
      state.user = payload;
    },
    SET_PAGE_INFO_LINE1 (state, payload) {
      const pageName = initPage(state, payload);
      state.page[pageName].info.line1 = payload.value;
    },
    SET_PAGE_INFO_LINE2 (state, payload) {
      const pageName = initPage(state, payload);
      state.page[pageName].info.line2 = payload.value;
    }
  },
  actions: {},
  plugins: [vuexPersist.plugin]
});
