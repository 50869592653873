import { Logger } from '@/services/common/Logger';
import { isOnline } from '@/util/DeviceUtil';

export const getPageName = () => {
  if (window.location === null || window.location === undefined || window.location.hash === null || window.location.hash === undefined) {
    return '404';
  }

  const hash = (typeof window.location.hash === 'string' ? window.location.hash : '').replace('#/', '');
  const paramIndex = hash.indexOf('?');
  const pageName = (paramIndex >= 0 ? hash.substring(0, hash.indexOf('?')) : hash);

  return (pageName.length === 0 ? 'login' : pageName);
};

export const handleRequestError = async (error, router, i18n, defaultMessage) => {
  if (i18n === undefined) {
    Logger.exception(error);

    if (error.response !== undefined && error.response.status === 401) {
      // await router.push({ name: 'login' });
      window.location = '/';
    }

    return;
  }

  if (typeof error.message === 'string' && error.message === 'logout') {
    return i18n.t('common.error.logout');
  }

  if (!isOnline() || (typeof error.message === 'string' && error.message.includes('Network Error'))) {
    return i18n.t('common.error.offline');
  }

  if (typeof error.message === 'string' && error.message.includes('timeout')) {
    return i18n.t('common.error.timeout');
  }

  Logger.exception(error);

  if (error.response !== undefined && error.response.status === 401) {
    // await router.push({ name: 'login' });
    window.location = '/';
  }

  if (typeof defaultMessage === 'string') {
    return defaultMessage;
  }

  return i18n.t('common.error.default');
};
