import { Logger } from '@/services/common/Logger';
import { Ability } from '@casl/ability';
import UserRole from '@/services/order/UserRole';

export default function defineAbilitiesFor (user) {
  if (user === undefined || user === null || user.role === undefined) {
    return new Ability([
      {
        action: 'none',
        subject: 'all'
      }
    ]);
  }

  switch (user.role) {
    case UserRole.admin:
      // return new Ability([{
      //   action: 'manage',
      //   subject: 'all'
      // }]);
      return new Ability([{
        action: 'manage',
        subject: 'Overview'
      }, {
        action: 'manage',
        subject: 'CustomerService'
      }, {
        action: 'manage',
        subject: 'CustomerService.Prospect'
      }, {
        action: 'manage',
        subject: 'CustomerService.New'
      }, {
        action: 'manage',
        subject: 'CustomerService.Inactive'
      }, {
        action: 'manage',
        subject: 'CustomerService.Claim'
      }, {
        action: 'manage',
        subject: 'CustomerService.Refund'
      }, {
        action: 'manage',
        subject: 'Dashboard'
      }, {
        action: 'manage',
        subject: 'Order.Preparation'
      }, {
        action: 'manage',
        subject: 'Order.Pickup'
      }, {
        action: 'manage',
        subject: 'Order.Delivery.All'
      }, {
        action: 'manage',
        subject: 'Order.Delivery.My'
      }, {
        action: 'manage',
        subject: 'Order.All'
      }, {
        action: 'read',
        subject: 'Order.Data.Product'
      }, {
        action: 'read',
        subject: 'Order.Data.Customer'
      }, {
        action: 'read',
        subject: 'Order.Data.Detail'
      }, {
        action: 'read',
        subject: 'Order.Data.Detail.Price'
      }, {
        action: 'read',
        subject: 'Order.Data.Detail.Discount'
      }, {
        action: 'manage',
        subject: 'Search'
      }, {
        action: 'manage',
        subject: 'Marketing'
      }, {
        action: 'manage',
        subject: 'Distribution'
      }, {
        action: 'manage',
        subject: 'Prospecting'
      }, {
        action: 'manage',
        subject: 'UserSetting'
      }, {
        action: 'manage',
        subject: 'Order.Editor'
      }, {
        action: 'manage',
        subject: 'Admin.Location.BusinessHours'
      }, {
        action: 'manage',
        subject: 'Admin.Location.Task'
      }, {
        action: 'manage',
        subject: 'Admin.Location.User'
      }, {
        action: 'manage',
        subject: 'Admin.Location.UsersSchedule'
      }, {
        action: 'manage',
        subject: 'Admin.Location.DeliveryZones'
      }, {
        action: 'manage',
        subject: 'Admin.Meta.Distribution'
      }, {
        action: 'manage',
        subject: 'Admin.Meta.Product'
      }, {
        action: 'manage',
        subject: 'Admin.Meta.Discount'
      }, {
        action: 'read',
        subject: 'Report.Activity'
      }, {
        action: 'read',
        subject: 'Report.Accounting'
      }, {
        action: 'manage',
        subject: 'Report.Meta'
      }, {
        action: 'manage',
        subject: 'Purchase'
      }]);
    case UserRole.admin_accountant:
      return new Ability([{
        action: 'read',
        subject: 'Overview'
      }, {
        action: 'read',
        subject: 'Dashboard'
      }, {
        action: 'read',
        subject: 'Report.Accounting'
      }, {
        action: 'manage',
        subject: 'Report.Meta'
      }]);
    case UserRole.manager:
      return new Ability([{
        action: 'manage',
        subject: 'Dashboard'
      }, {
        action: 'manage',
        subject: 'Order.Preparation'
      }, {
        action: 'manage',
        subject: 'Order.Pickup'
      }, {
        action: 'manage',
        subject: 'Order.Delivery.All'
      }, {
        action: 'manage',
        subject: 'Order.Delivery.My'
      }, {
        action: 'manage',
        subject: 'Order.All'
      }, {
        action: 'read',
        subject: 'Order.Data.Product'
      }, {
        action: 'read',
        subject: 'Order.Data.Detail'
      }, {
        action: 'manage',
        subject: 'Search'
      }, {
        action: 'manage',
        subject: 'UserSetting'
      }, {
        action: 'manage',
        subject: 'Admin.Location.BusinessHours'
      }, {
        action: 'manage',
        subject: 'Admin.Location.Task'
      }, {
        action: 'manage',
        subject: 'Admin.Location.User'
      }, {
        action: 'manage',
        subject: 'Admin.Location.DeliveryZones'
      }, {
        action: 'read',
        subject: 'Report.Activity'
      }]);
    case UserRole.accountant:
      return new Ability([{
        action: 'read',
        subject: 'Dashboard'
      }, {
        action: 'read',
        subject: 'Report.Accounting'
      }]);
    case UserRole.picker:
      return new Ability([{
        action: 'read',
        subject: 'Dashboard'
      }, {
        action: 'manage',
        subject: 'Order.Preparation'
      }, {
        action: 'manage',
        subject: 'Order.Pickup'
      }, {
        action: 'read',
        subject: 'Order.Data.Product'
      }]);
    case UserRole.agent:
      return new Ability([{
        action: 'read',
        subject: 'Order.All'
      }, {
        action: 'read',
        subject: 'Order.Data.Product'
      }, {
        action: 'read',
        subject: 'Order.Data.Customer'
      }, {
        action: 'read',
        subject: 'Order.Data.Detail'
      }, {
        action: 'manage',
        subject: 'CustomerService'
      }, {
        action: 'manage',
        subject: 'CustomerService.Prospect'
      }, {
        action: 'manage',
        subject: 'CustomerService.Inactive'
      }, {
        action: 'manage',
        subject: 'CustomerService.New'
      }, {
        action: 'read',
        subject: 'Search'
      }, {
        action: 'read',
        subject: 'Admin.Meta.Distribution'
      }]);
    case UserRole.driver:
      return new Ability([{
        action: 'manage',
        subject: 'Order.Delivery.My'
      }, {
        action: 'read',
        subject: 'Order.Data.Product'
      }, {
        action: 'read',
        subject: 'Order.Data.Customer'
      }, {
        action: 'manage',
        subject: 'UserSetting'
      }]);
    case UserRole.distributor:
      return new Ability([{
        action: 'manage',
        subject: 'Distribution'
      }]);
    case UserRole.canvasser:
      return new Ability([{
        action: 'manage',
        subject: 'Prospecting'
      }]);
    case UserRole.commercial:
      return new Ability([{
        action: 'manage',
        subject: 'Prospecting'
      }, {
        action: 'read',
        subject: 'Order.Data.Detail'
      }, {
        action: 'read',
        subject: 'Search'
      }]);
    case UserRole.buyer:
      return new Ability([{
        action: 'manage',
        subject: 'Purchase'
      }]);
    default:
      Logger.exception(new Error('user role ' + String(user.role) + ' is not defined for ' + String(user.id)));
      return new Ability([{
        action: 'none',
        subject: 'all'
      }]);
  }
};
