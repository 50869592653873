import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import i18n from '@/i18n';
import '@/sass/overrides.sass';

Vue.use(Vuetify);

const theme = {
  primary: '#3498db',
  secondary: '#6bb9f0',
  tertiary: '#ecf0f1',
  quaternary: '#fafafa',
  quinary: '#636e72',
  senary: '#a6a8a9',
  white: '#ffffff',
  accent: '#3a539b',
  close: '#34495e',
  success: '#43a047',
  fail: '#e74c3c',
  error: '#e74c3c',
  alert: '#e74c3c',
  warning: '#f89406',
  info: '#6bb9f0',
  delivery: '#ffffff',
  onsite: '#6ab04c',
  pickup: '#2c3e50',
  shipping: '#8c7ae6',
  check: '#cfe2f3',
  purchase: '#f4cccc'
};

export default new Vuetify({
  lang: {
    t: (key, ...params) => i18n.t(key, params)
  },
  theme: {
    dark: false,
    themes: {
      dark: theme,
      light: theme
    }
  }
});
