import axios from 'axios';

const http = axios.create({
  baseURL: '/',
  headers: {
    common: {
      'Cache-Control': 'no-cache',
      Pragma: 'no-cache',
      Expires: '0'
    }
  },
  withCredentials: true,
  timeout: 35000
});

// // request interceptor
// http.interceptors.request.use(config => {
//   // ...
//   return config;
// }, err => {
//   return Promise.reject(err);
// });
//
// // response interceptor
// http.interceptors.response.use(response => {
//   // ...
//   return response;
// }, err => {
//   return Promise.reject(err);
// });

export default http;
