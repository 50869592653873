export default {
  admin: 'admin',
  admin_accountant: 'admin_accountant',
  manager: 'manager',
  accountant: 'accountant',
  picker: 'picker',
  agent: 'agent',
  driver: 'driver',
  distributor: 'distributor',
  canvasser: 'canvasser',
  commercial: 'commercial',
  buyer: 'buyer'
};
